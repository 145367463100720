.bankRecon_root {
  /* height: calc(100vh - 65px); */
  min-height: calc(100vh - 65px);
  border-left: 2px solid #f2f2f2;
  background-color: #fcfcfd;
  overflow-y: auto;
}

.heading {
  font-size: 24px;
  font-weight: 600;
  padding: 2rem;
}

.heading_tab_bar {
  background-color: white !important;
}

.bank_searchbar_container {
  display: flex;
  gap: 2rem;
  padding: 1rem 2rem 2rem;
  /* border-top: 1px solid #D7E0E9;
  border-bottom: 1px solid #D7E0E9; */
}

/* .dropdown_wrap {
  max-width: 120px !important;
} */

.production_datePicker {
  min-width: 9.5rem !important;
}

.posted_date_filter {
  padding-top: 1.5rem;
  font-size: 18px;
  color: #828282;
  /* font-weight: bold; */
}

.bankRecon_amount_input {
  padding-top: 1rem;
  max-width: 120px;
}

.status_select_container {
  padding-top: 0.8rem !important;
}

.status_select {
  min-width: 150px !important;
}

.status_select_container_row {
  /* padding-bottom: 0.2rem !important; */
}

.status_select_row {
  min-width: 50px !important;
}

.Btn_wrap {
  display: flex;
  gap: 1rem;
}

.filterBtn_wrap {
  padding-top: 0.7rem;
}

.filterBtn {
  color: white !important;
}

.exportBtn {
  background-color: white !important;
  padding: 0.4rem 1rem !important;
}

.exportBtn_wrap {
  padding-top: 0.7rem;
}

.prod_table_container {
  border-radius: 0 !important;
  min-height: calc(100vh - 350px) !important;
}

.prod_table_head_cell {
  background-color: #e7f7f4 !important;
}

.arrow_icon {
  cursor: pointer;
  padding: 16px 0 10px 16px !important;
  color: #697386 !important;
}

.posted_date {
  padding: 16px 16px 16px 32px !important;
}

.posted_date_row {
  padding-left: 32px !important;
}

.bank_datePicker {
  max-width: 125px !important;
}

.bank_datePicker .MuiIconButton-root {
  padding: 0 !important;
}

.bank_datePicker .MuiSvgIcon-root {
  width: 20px !important;
  height: 20px !important;
}

.bankRecon_root .MuiTableCell-root {
  font-size: 14px !important;
  font-weight: 500 !important;
  /* padding: 0 !important; */
}

.MuiTablePagination-root {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.MuiToolbar-gutters {
  padding-left: 152px;
  padding-right: 152px;
}

.MuiTablePagination-root {
  border-radius: 10px;
  border: solid 1px #e0e0e0;
}

.progressBar {
  padding-top: 1rem;
}

.export_btn_container {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  padding-right: 8.8rem;
  padding-bottom: 2rem;
  gap: 1rem;
}

.download_btn {
  color: white !important;
}

.table_cell_payor_name {
  max-width: 120px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table_cell_check_number {
  max-width: 120px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}