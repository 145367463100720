.prodSummary_root {
  /* height: calc(100vh - 65px); */
  min-height: calc(100vh - 65px);
  border-left: 2px solid #f2f2f2;
  background-color: #fcfcfd;
  overflow-y: auto;
}

.heading {
  font-size: 24px;
  font-weight: 600;
  padding: 2rem;
}

.heading_tab_bar {
  background-color: white !important;
}

.tab_container {
  padding: 0 !important;
}

.searchbar_container {
  display: flex;
  gap: 2rem;
  padding: 1rem 2rem 1rem;
  /* border-top: 1px solid #D7E0E9;
  border-bottom: 1px solid #D7E0E9; */
}

.dropdown_wrap_container {
  padding-top: 1rem;
}

.dropdown_wrap {
  /* max-width: 150px !important; */
}

.production_date_dropdown {
  display: flex;
  align-items: center;
}

/* .dropdown_wrap_year {
  max-width: 120px !important;
} */

.MuiMenu-paper .MuiMenu-list {
  max-height: 210px !important;
}

.Btn_wrap {
  display: flex;
  gap: 0.7rem;
  padding-top: 1rem;
}

.filterBtn_wrap {
  padding-top: 0.7rem;
  cursor: pointer;
}

.filterBtn {
  color: white !important;
}

.exportBtn {
  background-color: white !important;
  padding: 0.4rem 1rem !important;
}

.exportBtn_wrap {
  padding-top: 0.7rem;
}

.table_sub_head_row {
  background-color: #fafbfd;
}

.table_sub_row_cell {
  border-right: 1px solid #afc6c6;
  border-left: 1px solid #afc6c6;
}

.table_sub_row {
  display: flex;
  /* gap: 1rem; */
}

.table_sub_head_cell {
  min-width: 110px;
}

.table_body_sub_row {
  display: flex;
  /* gap: 1rem; */
}

.table_body_sub_row_cell {
  border-right: 1px solid #afc6c6;
  border-left: 1px solid #afc6c6;
}

.table_body_sub_cell {
  min-width: 110px;
}

.month_name {
  border-right: 1px solid #afc6c6;
  border-left: 1px solid #afc6c6;
}

.provider_name {
  padding-left: 2rem !important;
  cursor: pointer;
  min-width: 120px;
}

.provider_name_table2 {
  padding-left: 2rem !important;
  background-color: #fafbfd;
}

.provider_name_table2_text {
  color: black;
  font-weight: 600;
}

.month_name {
  padding-left: 2rem !important;
}

.totals_name_column1 {
  padding-left: 2rem !important;
  color: black;
  font-weight: 700 !important;
  background-color: #fafbfd;
}

.totals_name {
  font-weight: 700 !important;
  color: black;
  background-color: #fafbfd;
  border-right: 1px solid #afc6c6;
  border-left: 1px solid #afc6c6;
}

.graph_container {
  padding: 2rem;
}

.graph_card {
  height: 390px;
  /* width: 1140px; */
  border-radius: 8px;
  background-color: white;
  padding: 2rem;
}

.graph_heading {
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.graph_content {
  height: 400px;
}
